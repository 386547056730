import CssBaseline from "@material-ui/core/CssBaseline";
import { useContext } from "react";
import Copyright from "../components/assets/Copyright";
import NoPermission from "../components/assets/NoPermission";
import PageHome from "../components/home/PageHome";
import Dashboard from "../components/navigation/Dashboard";
import UserContext from "../src/UserContext";

function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    (JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin ||
      JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser)
  ) {
    return "authenticated";
  } else {
    return "notauthenticated";
  }
}

export default function Medias() {
  let { keycloakstate } = useContext(UserContext);

  return (
    <div>
      <CssBaseline />
      {getUserType(keycloakstate) == "authenticated" ? (
        <Dashboard>
          <PageHome />
          <Copyright />
        </Dashboard>
      ) : (
        <Dashboard>
          <NoPermission />
        </Dashboard>
      )}
    </div>
  );
}
